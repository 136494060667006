<script setup></script>
<template>
  <div id="header" class="row">
    <div class="col-1"></div>
    <div class="col">
      <nav id="navbar" class="navbar">
        <a class="navbar-brand" href="/">
          <img
            src="../assets/logos/Logo_header.svg"
            alt="Digtop votre nouvelle plateforme, alternative à doctolib"
            class="d-inline-block align-center"
          />
        </a>
        <div class="nav-item-mobile">
          <button
            id="navbar-toggler"
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#nav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src="@/assets/login/Menu.svg" alt="" class="toggle-img" />
          </button>
          <li class="nav-item hide-laptop">
            <a class="btn btn-success" href="/aide">?</a>
          </li>
        </div>
        <div id="nav" class="navbarNav collapse">
          <ul class="nav me-auto">
            <li class="nav-item m-b">
              <a class="btn btn-warning" target="_blank" :href="app_host"
                >Vous êtes praticien ?</a
              >
              <a class="btn btn-light" target="_blank" :href="app_host"
                >Vous êtes praticien ?</a
              >
            </li>
            <li class="nav-item">
              <a
                class="btn btn-primary"
                target="_blank"
                :href="app_host + '/session/login'"
                >Se connecter</a
              >
              <a
                class="btn btn-light"
                target="_blank"
                :href="app_host + '/session/login'"
                >Se connecter</a
              >
            </li>
            <li class="nav-item hide-mobile">
              <a class="btn btn-success" href="/aide">?</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
    <div class="col-1"></div>
  </div>
</template>

<script>
const app_host = process.env.VUE_APP_DIGTOP_APP_HOST;

export default {
  name: "HeaderSection",
  data() {
    window.addEventListener("scroll", () => {
      if (
        document
          .getElementsByClassName("navbar-toggler")[0]
          .getAttribute("aria-expanded") == "true" &&
        window.scrollY > 0
      ) {
        document.getElementsByClassName("navbar-toggler")[0].click();
      }
    });
    return {
      app_host: app_host,
    };
  },
  methods: {},
};
</script>

<style scoped>
.row {
  --bs-gutter-x: 0;
}
button#navbar-toggler {
  border: none;
}

#header {
  color: ghostwhite;
  background-color: #fff;
  line-height: 60px;
  box-shadow: 0 8px 18px -4px rgba(0, 0, 0, 0.1);
  position: relative;
}

#header .col-1 {
  width: 12%;
}

#navbar .nav .nav-item .btn {
  line-height: 30px !important;
  color: white !important;
  border: none;
}

.btn-success {
  font-family: "Rubik", sans-serif;
  background-color: #3aaa35;
  border: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 0;
  width: 42px;
  margin-left: 18px;
  font-weight: 400;
  font-size: 24px;
}

#navbar .nav .nav-item .btn-warning {
  background-color: #f39200;
  border-top-left-radius: 5px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 0;
  width: 226px;
  margin-right: 18px;
  font-weight: 500;
}

#navbar .nav .nav-item .btn-primary {
  background-color: #0993e8;
  border-top-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 5px;
  width: 167px;
}

.navbar-toggler {
  display: none;
}

#nav.collapse:not(.show) {
  display: flex;
}

.nav-item-mobile {
  display: flex;
}

.nav-item-mobile > li {
  list-style-type: none;
}

.nav-item-mobile > li .btn-success {
  margin-left: 2px;
  font-size: 19px;
}

@media (max-width: 1299px) {
  #header .col-1 {
    width: 5%;
  }
}

@media (min-width: 1300px) and (max-width: 1399px) {
  #header .col-1 {
    width: 7%;
  }
}

@media (min-width: 1400px) and (max-width: 1499px) {
  #header .col-1 {
    width: 9%;
  }
}

@media (min-width: 1500px) and (max-width: 1599px) {
  #header .col-1 {
    width: 8%;
  }
}

@media (max-width: 697px) {
  .navbarNav {
    border-radius: 4px;
    background-color: rgb(245, 246, 248);
    margin-top: 13em;
    margin-left: 20%;
    position: absolute;
    z-index: 99;
  }

  .navbar-toggler {
    display: block !important;
    line-height: none !important;
    float: right;
  }

  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
  }

  .col-1[data-v-61dd7a3d] {
    width: 6%;
  }

  #nav.collapse:not(.show) {
    display: none !important;
  }
  .toggle-img {
    width: 1.5em !important;
  }
  .nav {
    position: unset;
    display: block;
    width: 14em;
    height: 7em;
  }

  .hide-mobile {
    display: none !important;
  }

  .btn-warning,
  .btn-primary {
    display: none;
  }

  #navbar .nav .nav-item .btn {
    color: black !important;
    font-weight: 300;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    margin: 5%;
  }

  .nav-item {
    margin: 3px;
  }

  .m-b {
    margin-bottom: -1.8em;
  }
}

@media (min-width: 698px) {
  .hide-laptop {
    display: none;
  }

  .btn-light {
    display: none;
  }
}

@media (max-width: 667px) and (min-width: 520px) {
  .navbarNav {
    margin-left: 46% !important;
  }
}

@media (max-width: 519px) and (min-width: 480px) {
  .navbarNav {
    margin-left: 42% !important;
  }
}

@media (max-width: 479px) and (min-width: 452px) {
  .navbarNav {
    margin-left: 39% !important;
  }
}

@media (max-width: 451px) and (min-width: 432px) {
  .navbarNav {
    margin-left: 36% !important;
  }
}

@media (max-width: 431px) and (min-width: 415px) {
  .navbarNav {
    margin-left: 33% !important;
  }
}

@media (max-width: 414px) and (min-width: 392px) {
  .navbarNav {
    margin-left: 30% !important;
  }
}

@media (max-width: 391px) and (min-width: 374px) {
  .navbarNav {
    margin-left: 27% !important;
  }
}
</style>
