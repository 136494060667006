<template>
  <div>
    <div class="teleconsultation">
      <div class="titre-pre">
        <h1 class="title">Démarrer une téléconsultation</h1>
      </div>
      <h1 class="title sub">Interface compte Digtop patient</h1>
      <div class="paragraph">
        <div>
          <p>
            1.
            <span> Se connecter à votre compte Digtop patient</span>
          </p>

          <p>
            2.
            <span>
              Cliquez sur le bouton "
              <span class="subtitle">Démarrer ma consulation</span>"</span
            >
          </p>
          <div>
            <img src="@/assets/help/teleConsultation/1.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/help/teleConsultation/2.jpg" alt="" />
          </div>
          <div class="Rmq">
            <p>
              <span class="subtitle">Remarque</span>
              <br />
              Vous pouvez également accéder à la téléconsultation à partir du
              mail de rappel de la téléconsultation en cliquant sur le lien
              fourni dans le courriel.
            </p>
          </div>
          <div>
            <img src="@/assets/help/teleConsultation/3.jpg" alt="" />
          </div>
          <h1 class="title sub">Interface compte Digtop praticien</h1>
          <p>
            1.
            <span> Se connecter à votre compte Digtop praticien </span>
          </p>
          <p>
            2.
            <span>
              Dans votre agenda Dans votre agenda cliquez sur votre rdv en ligne
            </span>
          </p>
          <div>
            <img src="@/assets/help/teleConsultation/4.jpg" alt="" />
          </div>
          <p>
            3.
            <span> Cliquez sur "Démarrer la téléconsultation" </span>
          </p>
          <div>
            <img src="@/assets/help/teleConsultation/5.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/help/teleConsultation/6.jpg" alt="" />
          </div>
          <div>
            <img src="@/assets/help/teleConsultation/7.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientPage",
};
</script>
<style scoped>
.Rmq {
  padding: 5% 5%;
  background-color: #f1f7ff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 200%;

  color: #d93f26;
}

.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}
.sub {
  font-family: "Poppins";
  font-style: normal;
  margin: 2% 5% 5% 4%;
  font-weight: 700;
  font-size: 18px;

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #f39200;
}
.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.paragraph img {
  width: 85%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.subtitle {
  font-weight: 700;

  font-size: 16px;
}
@media (max-width: 864px) {
  .paragraph {
    margin-left: 1.5%;
  }
  .titre-pre {
    margin-left: -1%;
    margin-top: 2%;
  }

  .sub {
    margin: 2% 5% 5% 1%;
  }
}
@media (max-width: 767px) {
  .teleconsultation {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media (min-width: 865px) and (max-width: 1400px) {
  .teleconsultation {
    margin-top: 7%;
  }
}
@media (min-width: 1400px) {
  .teleconsultation {
    margin-top: 5%;
  }
}
</style>
