import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import primevue from "primevue/config";
import vueCookies from 'vue-cookies';

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap";
import "jquery/dist/jquery.slim.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const app = createApp(App);
app.use(router).use(primevue).use(vueCookies).mount("#app");
