<script setup></script>
<template>
  <div>
    <div class="discover">
      <div class="titre-pre">
        <h1 class="title">Présentation de Digtop</h1>
      </div>
      <div class="paragraph">
        <p>
          Afin de rendre votre utilisation plus facile, Digtop vous a fourni des
          raccourcis sur sa page d'accueil vous permettant d'accéder rapidement
          aux différentes fonctionnalités du site.
        </p>
        <h2 class="sommaire">Sommaire</h2>
        <div class="menu">
          <p class="d-flex">
            1.
            <span>
              <a href="#" @click.prevent="scrollToSection($refs.connecterRef)">
                <h3>Se connecter</h3></a
              ></span
            >
          </p>
          <p class="d-flex">
            2.
            <span>
              <a href="#" @click.prevent="scrollToSection($refs.praticien)">
                <h3>Espace réservé au praticien</h3>
              </a></span
            >
          </p>
          <p class="d-flex">
            3.<span>
              <a href="#" @click.prevent="scrollToSection($refs.aide)">
                <h3>Besoin d’aide</h3></a
              >
            </span>
          </p>
          <p class="d-flex">
            4.
            <span
              ><a href="#" @click.prevent="scrollToSection($refs.search)">
                <h3>Moteur de recherche</h3></a
              >
            </span>
          </p>
        </div>
        <div>
          <img
            src="@/assets/help/dicover-digtop/1.jpg"
            alt="image demonstratif"
          />
        </div>
        <section ref="connecterRef">
          <div class="connecter">
            <p class="subtitle">1. Se connecter</p>
            <p class="subparagraph">
              Cliquez sur "Se connecter" pour vous connecter à votre compte ou
              pour vous inscrire à Digtop.
            </p>
            <div class="order ml-1">
              <p>&#8226; Je m’inscris en tant que patient</p>
              <p>&#8226; Je m’inscris en tant que particien</p>
              <p>&#8226; Se connecter à mon compte Digtop</p>
            </div>
            <div>
              <img
                src="@/assets/help/dicover-digtop/2.jpg"
                alt="Se connecter"
              />
            </div>
          </div>
        </section>
        <section ref="praticien">
          <div class="connecter">
            <p class="subtitle">2. Espace réservé au praticien</p>
            <p class="subparagraph">
              Cet espace est destiné exclusivement aux professionnels de
              médecines alternatives et complémentaires.
            </p>
            <p class="subparagraph">
              Cliquez sur "Vous êtes praticien" et découvrez nos différentes
              offres et solutions dédiées aux praticiens.
            </p>
            <div class="order ml-1">
              <p>&#8226; Se connecter</p>
              <p>&#8226; S’inscrire</p>
            </div>
            <div>
              <img
                src="@/assets/help/dicover-digtop/3.jpg"
                alt="S'inscrire particien "
              />
            </div>
            <div>
              <img src="@/assets/help/dicover-digtop/4.jpg" alt="S'inscrire" />
            </div>
          </div>
        </section>
        <section ref="aide">
          <div class="connecter">
            <p class="subtitle">3. Besoin d’aide</p>
            <div>
              <img
                style="margin-top: 1%"
                src="@/assets/help/dicover-digtop/5.jpg"
                alt=" Besoin d'aide"
              />
            </div>
          </div>
        </section>
        <section ref="search">
          <div class="connecter">
            <p class="subtitle">4. Moteur de recherche</p>
            <p class="subparagraph">
              Il est possible de rechercher un praticien par son nom, sa
              spécialité ou par son lieu d’exercice :
            </p>
            <div class="searchpictures">
              <div>
                <img
                  src="@/assets/help/dicover-digtop/6.jpg"
                  alt="Recherche par nom"
                />
              </div>
              <div>
                <img
                  src="@/assets/help/dicover-digtop/7.jpg"
                  alt="recherche par spécialiste"
                />
              </div>
              <div>
                <img
                  src="@/assets/help/dicover-digtop/8.jpg"
                  alt="recherche par localisation"
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "DiscoverDigtop",
  methods: {
    scrollToSection(sectionRef) {
      sectionRef.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
.discover {
  margin-top: 6%;
  margin-bottom: 4%;
}

.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}

.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.sommaire {
  font-weight: 700 !important;
  font-size: 18px !important;
  margin-bottom: 2%;
}

.menu {
  font-size: 14px;
  line-height: 14px;
}

.menu span {
  color: #0993e8;
  padding-left: 0.8%;
}
.menu h3 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  margin-bottom: -1%;
}

.paragraph img {
  width: 85%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.subtitle {
  font-weight: 700;

  font-size: 16px;
}

.connecter {
  margin-left: 0.5%;
}

.subparagraph {
  margin-top: 2%;
  margin-left: 0.9%;
  margin-bottom: 1%;
}

.order {
  color: #0993e8;
  line-height: 14px;
  margin-top: 2%;
}
.order {
  color: #0993e8;
  line-height: 14px;
  margin-top: 2%;
}

.searchpictures img {
  margin-bottom: -2%;
}

.ml-1 {
  margin-left: 1% !important;
  margin-top: 4% !important;
}
@media (max-width: 864px) {
  .paragraph {
    margin-left: 1%;
  }
  .title {
    margin-top: 2%;
    margin-left: -1%;
  }
}
@media (min-width: 865px) and (max-width: 1400px) {
  .discover {
    margin-top: 7%;
  }
}
@media (max-width: 767px) {
  .discover {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
</style>
