<template>
  <div class="appointment"><h1 class="title">Mes rendez-vous</h1></div>
</template>

<script>
export default {
  name: "PatientPage",
};
</script>
<style scoped>
.title {
  color: #1f294e;
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

@media (max-width: 864px) {
  .title {
    margin-top: 2%;
    margin-left: -1%;
  }
}
@media (max-width: 767px) {
  .appointment {
    margin-left: 9%;
    margin-top: 6%;
  }
}
</style>
