<template>
  <div>
    <div class="contactUs">
      <div class="titre-pre">
        <h1 class="title">Restons en contact</h1>
      </div>

      <div class="paragraph">
        <p>
          Nous serons ravis de vous lire. Nous nous engageons à vous répondre
          dans les meilleurs délais.
        </p>
        <br />
        <div class="col-11 col-xxl-9 col-xl-5 col-lg-10">
          <div class="card">
            <form id="contact-form" @submit.prevent="requestContact" class="card-body needs-validation" novalidate>
              <div class="mb-3">
                <div class="row d-flex">
                  <div class="col-5 pe-3">
                    <input id="name" type="text" class="form-control" placeholder="Nom" maxlength="50" required />
                    <div class="invalid-feedback">Veuillez saisir votre nom.</div>
                  </div>
                  <div class="col">
                    <input id="email" type="email" class="form-control" placeholder="name@example.com" maxlength="320"
                      required />
                    <div class="invalid-feedback">Veuillez saisir votre courriel</div>
                  </div>
                </div>
              </div>
              <div class="mb-3">
                <input id="object" type="text" class="form-control" placeholder="Objet" maxlength="50" required />
                <div class="invalid-feedback">Veuillez saisir l’objet de votre demande.</div>
              </div>
              <div class="mb-3">
                <textarea id="message" class="form-control" placeholder="Message" rows="8" maxlength="300"
                  required></textarea>
                <div class="invalid-feedback">Veuillez saisir votre message</div>
              </div>
              <div class="d-grid gap-2">
                <button class="btn btn-primary">Envoyer</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <spinner-component />
  <success-modal />
  <error-modal />
</template>

<script>
import spinnerComponent from "@/components/shared/spinner.vue";
import SuccessModal from "@/components/shared/SuccessModalContactUs.vue";
import ErrorModal from "@/components/shared/ErrorModal.vue";
import * as bootstrap from "bootstrap";
import axios from "axios";
import $ from "jquery";

export default {
  name: "contactUs",
  components: {
    spinnerComponent: spinnerComponent,
    SuccessModal: SuccessModal,
    ErrorModal: ErrorModal,
  },
  methods: {
    requestContact(event) {
      let form = document.querySelector("#contact-form");
      if (!form.checkValidity()) {
        form.classList.add("was-validated");
        event.preventDefault();
        event.stopPropagation();
      } else {
        $("#spinner").show();
        form.classList.remove("was-validated");
        let contact = {
          name: document.querySelector("#name").value,
          phone: "[non renseigné]",
          email: document.querySelector("#email").value,
          object: document.querySelector("#object").value,
          message: document.querySelector("#message").value,
        };
        axios
          .post(
            "/api/v1.0/anonymousUser-management/anonymousUser/contactUs",
            contact
          )
          .then(() => {
            new bootstrap.Modal($("#success-modal-contact")).show();
            document.querySelector("#name").value = "";
            document.querySelector("#email").value = "";
            document.querySelector("#object").value = "";
            document.querySelector("#message").value = "";
            $("#spinner").hide();
          })
          .catch((e) => {
            $("#spinner").hide();
            new bootstrap.Modal($("#error-modal")).show();
            console.error(e);
          });
      }
    },
  }
};
</script>
<style scoped>
.invalid-feedback {
  font-family: Poppins, "Courier New", Courier, monospace;
  color: #D93F26;
  font-size: 12px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
}

.was-validated .form-control:invalid:focus {
  box-shadow: inset 0 1px 1px rgb(255, 255, 255), 0 0 8px #D93F26;
}

.was-validated .form-control:valid:focus {
  box-shadow: inset 0 1px 1px rgb(255, 255, 255), 0 0 8px #3aaa35;
}

.col-xxl-9 {
  width: 70%;
}

.form-control {
  border: 0.5px solid #cac9c8;
  border-radius: 0;
  font-family: Poppins, "Courier New", Courier, monospace;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 0.8rem 0.75rem;
}

.card {
  padding: 3%;
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border: none;
}

.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}

.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.paragraph img {
  width: 85%;
  margin-top: 5%;
  margin-bottom: 5%;
}

@media (max-width: 1550px) {
  .card {
    width: 130%;
  }
}

@media (max-width: 864px) {
  .paragraph {
    margin-left: 1%;
  }

  .title {
    margin-top: 2%;
    margin-left: -1%;
  }
}

@media (max-width: 767px) {
  .contactUs {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}

@media (min-width: 865px) and (max-width: 1400px) {
  .contactUs {
    margin-top: 7%;
  }
}

@media (min-width: 1400px) {
  .contactUs {
    margin-top: 5%;
  }
}
</style>
