<template>
  <header>
    <header-section />
  </header>

  <body>
    <cookies-banner />
    <router-view />
  </body>
  <footer>
    <footer-section />
  </footer>
</template>

<script>
import HeaderSection from "./components/Header.vue";
import FooterSection from "./components/Footer.vue";
import CookiesBanner from "@/components/CookiesBanner.vue";

export default {
  name: "App",
  components: {
    CookiesBanner,
    HeaderSection,
    FooterSection,
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-size: 16px;
  text-align: left;
  color: #1f294e;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #ecebec;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #f39200;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #c57702e7;
}
</style>
