<template>
  <div id="spinner">
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "spinnerComponent",
};
</script>
<style scoped>
.spinner-border {
  z-index: 20001;
  width: 4rem;
  height: 4rem;
  position: fixed;
  top: calc(50% - (58px / 2));
  right: calc(50% - (58px / 2));
  color: #f39200;
}

#spinner {
  overflow-x: hidden;
  z-index: 2000;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}
</style>
