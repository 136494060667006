<template>
  <div>
    <div class="access">
      <div class="titre-pre">
        <h1 class="title">Accéder à votre compte Digtop</h1>
      </div>
      <div class="paragraph">
        <p>
          1. <span> Aller sur <a :href="app_host" alt="">Digtop.fr</a></span>
        </p>
        <p>
          2.
          <span>
            Renseigner votre adresse mail et mot de passe que vous avez utilisés
            lors de votre inscription puis cliquer sur le bouton "<span
              class="subtitle"
            >
              Se connecter
            </span>
            "
          </span>
        </p>
      </div>
      <div class="paragraph">
        <div>
          <img src="@/assets/help/email/1.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let app_host = process.env.VUE_APP_DIGTOP_APP_HOST;
export default {
  name: "AccessAccount",
  data() {
    return {
      app_host: app_host,
    };
  },
};
</script>
<style scoped>
.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}

.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.paragraph img {
  width: 85%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.subtitle {
  font-weight: 700;

  font-size: 16px;
}
@media (max-width: 864px) {
  .paragraph {
    margin-left: 1%;
  }
  .title {
    margin-top: 2%;
    margin-left: -1%;
  }
}
@media (max-width: 767px) {
  .access {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media (min-width: 865px) and (max-width: 1400px) {
  .access {
    margin-top: 7%;
  }
}
@media (min-width: 1400px) {
  .access {
    margin-top: 5%;
  }
}
</style>
