<template>
    <div class="modal fade" id="success-modal-contact" tabindex="-1" aria-labelledby="SuccessModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div id="modal-msg" class="modal-body">
                    <p>
                        Nous vous remercions d'avoir pris le temps de visiter notre site et
                        de nous avoir contactés.<br />
                        Un de nos conseillers vous contactera dans les plus brefs délais.
                    </p>
                </div>
                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SuccessModalContact",
};
</script>
<style scoped>
#success-modal-contact .modal-header {
    border-bottom: none;
}

#success-modal-contact .modal-footer {
    border-top: none;
}

#success-modal-contact .modal-body p {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    text-align: center;
    letter-spacing: 0em;
}

#success-modal-contact .modal-body p {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #3aaa35;
}

#success-modal-contact .modal-dialog .modal-content {
    background-color: #e9f2de;
}
</style>