<template>
  <div>
    <div class="share">
      <div class="titre-pre">
        <h1 class="title">Partager et recevoir des documents</h1>
      </div>
      <h1 class="title sub">Interface compte Digtop patient</h1>
      <div class="paragraph">
        <div>
          <p>
            1.
            <span>Se connecter à votre compte Digtop patient</span>
          </p>
          <p>
            2.
            <span
              >Dans la rubrique "Mes rendez-vous à venir" cliquez sur le bouton
              Cliquez sur "<span class="subtitle">Partager un document</span
              >"</span
            >
          </p>
          <div>
            <img src="@/assets/help/share/1.jpg" alt="" />
          </div>
          <p>
            3.
            <span>
              Choisissez depuis votre ordinateur le document à transmettre à
              votre professionnel puis cliquez sur le bouton "<span
                class="subtitle"
              >
                Envoyer</span
              >"</span
            >
          </p>
          <div>
            <img src="@/assets/help/share/2.jpg" alt="" />
          </div>
        </div>
      </div>
      <h1 class="title sub">Interface compte Digtop praticien</h1>
      <div class="paragraph">
        <div>
          <p>
            1.
            <span
              >Ouvrir le menu déroulant en cliquant sur la petite flèche</span
            >
          </p>
          <p>
            2.
            <span
              >Cliquez sur "<span class="subtitle">Mes consultations</span
              >"</span
            >
          </p>
          <div>
            <img src="@/assets/help/share/3.jpg" alt="" />
          </div>
        </div>
        <p>
          3.
          <span>Cliquez sur une consultation</span>
        </p>
        <div>
          <img src="@/assets/help/share/4.jpg" alt="" />
        </div>
        <p>
          4.
          <span
            >La fiche consultation s'ouvre et vous pouvez transmettre des
            documents en cliquant sur le bouton "<span class="subtitle"
              >Partager un document</span
            >"</span
          >
        </p>
        <div>
          <img src="@/assets/help/share/5.jpg" alt="" />
        </div>
        <p>
          5.
          <span
            >Choisissez depuis votre ordinateur le document à transmettre à
            votre patient puis cliquez sur le bouton "<span class="subtitle"
              >Envoyer</span
            >"</span
          >
        </p>
        <div><img src="@/assets/help/share/6.jpg" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShareDocument",
};
</script>
<style scoped>
.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}
.sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  margin: 2% 5% 5% 4%;
  color: #f39200;
}
.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.paragraph img {
  width: 85%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.subtitle {
  font-weight: 700;

  font-size: 16px;
}
@media (max-width: 864px) {
  .paragraph {
    margin-left: 1.5%;
  }
  .titre-pre {
    margin-left: -1%;
    margin-top: 2%;
  }

  .sub {
    margin: 2% 5% 5% 1%;
  }
}
@media (max-width: 767px) {
  .share {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media (min-width: 865px) and (max-width: 1400px) {
  .share {
    margin-top: 7%;
  }
}
@media (min-width: 1400px) {
  .share {
    margin-top: 5%;
  }
}
</style>
