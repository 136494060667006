<template>
  <div class="config">
    <div class="titre-pre">
      <h1 class="title">Configurer votre compte Digtop</h1>
    </div>
    <div class="paragraph">
      <h1 class="seconde-title">Interface compte Digtop patient</h1>

      <div class="">
        <p>
          1.<span>
            Ouvrir le menu déroulant en cliquant sur la petite flèche
          </span>
        </p>
        <p>2. <span>Cliquez sur "Mon compte"</span></p>
      </div>
      <div>
        <img
          src="@/assets/help/configure-account/1.jpg"
          alt="image du compte Digtop patient"
        />
      </div>
      <div class="">
        <p class="subparagraph">
          Vous pouvez modifier ou ajouter vos informations personnelles
        </p>

        <div>
          <img
            src="@/assets/help/configure-account/2.jpg"
            alt=" Modification des informations personnelles"
          />
        </div>
      </div>
    </div>
    <div class="paragraph mt-1">
      <h1 class="seconde-title">Interface compte Digtop praticien</h1>

      <div class="">
        <p>1.<span> Cliquez sur votre avatar </span></p>
        <p>2. <span>Cliquez sur Mon compte</span></p>
        <div>
          <img
            src="@/assets/help/configure-account/3.jpg"
            alt="accès au profil du praticien"
          />
        </div>
        <p>
          2.
          <span
            >Vous pouvez modifier ou ajouter des informations à votre compte
            puis cliquez sur le bouton
            <strong> “ Enregistrer les informations “ </strong>
          </span>
        </p>
        <div>
          <img
            src="@/assets/help/configure-account/4.jpg"
            alt=" Modification des informations personnelles du profil praticien"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientPage",
};
</script>
<style scoped>
.title {
  color: #1f294e;
  font-weight: 600;
  font-size: 20px;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}
.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}
.seconde-title {
  color: #f39200;
  font-weight: 600 !important;
  font-size: 18px !important;
  margin: 2% 5% 4% 0%;
}
.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.paragraph img {
  width: 85%;
}

.subparagraph {
  margin-top: 2%;
  margin-left: 0.9%;
  margin-bottom: 1%;
}
.mt-1 {
  margin-top: 5% !important;
}
@media (max-width: 864px) {
  .paragraph {
    margin-left: 1%;
  }
  .title {
    margin-top: 2%;
    margin-left: -1%;
  }
}
@media (max-width: 767px) {
  .config {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media (min-width: 865px) and (max-width: 1400px) {
  .config {
    margin-top: 7%;
  }
}
@media (min-width: 1400px) {
  .config {
    margin-top: 5%;
  }
}
</style>
