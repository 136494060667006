<template>
  <div class="container-fluid" id="footer">
    <div class="row footer-details">
      <div
        class="col-xs-12 col-sm-5 col-md-1 col-md-tab-1 col-xl-1 col-xxl-1"
      ></div>
      <div class="col-xs-12 col-sm-12 col-md-3 col-xl">
        <img
          class="logo"
          src="@/assets/logos/Logo_footer.svg"
          alt="application alternative à doctolib"
        />
        <div class="dropdown pt-5 pb-4">
          <strong>À propos de Digtop </strong>
          <a
            class="hide-pc arrow-toggle"
            data-bs-toggle="collapse"
            href="#collapse1"
            role="button"
            aria-expanded="true"
            aria-controls="collapse1"
            ><img
              class="icon-arrow-down"
              alt=""
              src="@/assets/footer/collapse_show.svg"
            />
            <img
              class="icon-arrow-up"
              alt=""
              src="@/assets/footer/collapse_hide.svg"
            />
          </a>

          <ul class="collapse show pt-2" id="collapse1">
            <li>
              <a href="/aide">Besoin d'aide & contact</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 col-sm-5 col-md-1"></div>
      <div class="col-xs-12 col-sm-12 col-md-3 pb-4 m-tab">
        <div class="dropdown">
          <h2>
            Trouver votre praticien

            <a
              class="hide-pc arrow-toggle"
              data-bs-toggle="collapse"
              href="#collapse2"
              role="button"
              aria-expanded="false"
              aria-controls="collapse2"
              ><img
                class="icon-arrow-down"
                alt=""
                src="@/assets/footer/collapse_show.svg" />
              <img
                class="icon-arrow-up"
                alt=""
                src="@/assets/footer/collapse_hide.svg"
            /></a>
          </h2>
          <ul class="collapse show pt-2" id="collapse2">
            <li>
              <a href="/search?category=Naturopathe">
                <h3 class="speciality">Naturopathie</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Acupuncteur">
                <h3 class="speciality">Acupuncteur</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Homéopathe">
                <h3 class="speciality">Homéopathie</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Phytothérapeute">
                <h3 class="speciality">Phytothérapie</h3></a
              >
            </li>
            <li>
              <a href="/search?category=PNL">
                <h3 class="speciality">PNL</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Ostéopathe">
                <h3 class="speciality">Ostéopathie</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Hypnothérapeute">
                <h3 class="speciality">Hypnose</h3>
              </a>
            </li>
            <li>
              <a href="/search?category=Chiropraticien">
                <h3 class="speciality">Chiropracteur</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Shiatsu">
                <h3 class="speciality">Shiatsu</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Sophrologue">
                <h3 class="speciality">Sophrologie</h3></a
              >
            </li>
            <li>
              <a href="/search?category=Nutritionniste">
                <h3 class="speciality">Nutritionniste</h3></a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-xs-12 col-sm-5 col-md-1"></div>
      <div class="col-xs-12 col-sm-12 col-md-3 mt-tab-">
        <div>
          <p>
            <strong>Digtop pour les praticiens :</strong>
          </p>
          <p>
            <span
              ><a :href="app_host">La solution Tout-en-Un DigtopPro</a></span
            >
          </p>
        </div>
        <div class="pt-5">
          <p>
            <strong>Retrouvez-nous :</strong>
          </p>
          <p>
            <span>Commencez Avec Digtop</span>
          </p>
          <div>
            <ul class="social-footer">
              <li style="padding-right: 5%">
                <a :href="fbLink" target="_blank">
                  <img src="@/assets/footer/iconFacebook.svg" alt="facebook" />
                  <p class="d-none">facebook</p>
                </a>
              </li>
              <li style="padding-right: 5%">
                <a :href="linkedInLink" target="_blank">
                  <img src="@/assets/footer/iconLinkedin.svg" alt="linkedin" />
                  <p class="d-none">linkedin</p>
                </a>
              </li>
              <li style="padding-right: 5%">
                <a :href="youtibeLink" target="_blank">
                  <img src="@/assets/footer/iconYoutube.svg" alt="youtube" />
                  <p class="d-none">youtube</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row row-version">
      <div class="col-1"></div>
      <div class="col-11">Version {{ appVersion }}</div>
    </div>
    <div class="footer-legal pt-3 pl-6">
      <div class="container justify-content-between"></div>
      <div class="row Fl">
        <div class="col-xs-12 col-sm-12 col-md-1"></div>
        <div class="col-xs-12 col-sm-12 col-md-9">
          CGU & Mentions légales | Copyright © {{ new Date().getFullYear() }}
          Digtop, tous droits réservés.
        </div>

        <div class="col-xs-12 col-sm-12 col-md-1 logo">
          <img
            class="img"
            src="../assets/logos/Logo_blanc.svg"
            alt="DIGTOP LOGO"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packageInfo from "../../package";
const app_host = process.env.VUE_APP_DIGTOP_APP_HOST;

export default {
  name: "FooterSection",
  data: () => ({
    appVersion: packageInfo.version,
    app_host: app_host,
    fbLink: "https://www.facebook.com/digtopbyinnteqsolutions/",
    linkedInLink: "https://www.linkedin.com/showcase/digtop/",
    youtibeLink: "https://www.youtube.com/channel/UCrpZL1fpI1bmN9ndK6V_y9Q",
  }),
};
</script>

<style scoped>
.Fl {
  max-width: 98%;
}
.row-version {
  font-size: 9px;
  background-color: #f5f5f5;
  line-height: 40px;
}
.arrow-toggle .icon-arrow-down,
.arrow-toggle.collapsed .icon-arrow-up {
  display: inline-block;
}
.arrow-toggle.collapsed .icon-arrow-down,
.arrow-toggle .icon-arrow-up {
  display: none;
}
.collapse li a:hover {
  color: #f39200 !important;
}
.row {
  --bs-gutter-x: 0;
}
.logo {
  margin-left: 2%;
}
.img {
  height: 33px;
  margin-bottom: 8%;
}
#footer .row.footer-details {
  background-color: #f5f5f5;
  padding: 7% 0;
  font-family: "Poppins", Verdana, Tahoma, sans-serif;
}

#footer .footer-legal {
  color: ghostwhite;
  background-color: #1f294e;
  height: 10%;
}

#footer li,
#footer span,
#footer strong {
  font-family: "Poppins", sans-serif;
  color: #1f294e;
}
#footer a {
  text-decoration-line: none;
  color: #1f294e;
}

#footer li {
  list-style-type: none;
  padding: 1% 0;
}

#footer ul {
  padding-left: 0;
  width: 100%;
  background-color: transparent;
  border: none;
}

ul.social-footer li {
  display: inline-block;
}

#footer strong {
  font-size: 18px;
  font-weight: 700;
}
#footer h2 {
  font-family: "Poppins", sans-serif;

  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  color: rgba(31, 41, 78, 1);
}
#footer li a .speciality {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(31, 41, 78, 1);
  line-height: 20px;
}
#footer .mt-6 {
  padding-top: 58%;
}
.titre-padding {
  padding-bottom: 4% !important;
}
.container-fluid {
  padding: 0;
}

@media (min-width: 1300px) and (max-width: 1399px) {
  .col-xl-1 {
    width: 7%;
  }
}
@media (max-width: 1299px) and (min-width: 994px) {
  .col-xl-1 {
    width: 5%;
  }
}

@media (min-width: 1000px) and (max-width: 1199px) {
  #footer .footer-legal .logo {
    margin-left: 6% !important;
  }
}
@media (min-width: 764px) {
  .hide-pc {
    display: none;
  }
}
@media (max-width: 767px) {
  .footer-details,
  .footer-legal {
    padding-left: 7% !important;
  }
  .logo {
    padding-top: 7%;
  }
}
@media (min-width: 768px) and (max-width: 993px) {
  .col-md-3 {
    width: 41% !important;
  }
  .m-tab {
    margin-top: 13%;
  }
  .mt-tab- {
    margin-top: -17%;
    margin-left: -2%;
  }
  .col-md-tab-1 {
    flex: 0 0 auto;
    width: 5%;
  }
}
@media (min-width: 1500px) {
  .col-xxl-1 {
    width: 11.333333%;
  }
}
</style>
