<template>
  <div>
    <div class="email">
      <div class="titre-pre">
        <h1 class="title">Créer votre compte Digtop</h1>
      </div>
      <h1 class="title sub">Vérifier votre adresse email</h1>
      <div class="paragraph">
        <p>
          Une fois, votre compte est créé, allez dans votre boite mail et suivez
          les indications envoyées, en cliquant sur le bouton “<span
            class="subtitle"
            >Activer mon compte</span
          >”
        </p>
        <div>
          <img src="@/assets/help/email/1.jpg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VerifyEmail",
};
</script>
<style scoped>
.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}
.sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  margin: 2% 5% 5% 4%;
  color: #f39200;
}
.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.paragraph img {
  width: 85%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.subtitle {
  font-weight: 700;

  font-size: 16px;
}
@media (max-width: 864px) {
  .paragraph {
    margin-left: 1.5%;
  }
  .titre-pre {
    margin-left: -1%;
    margin-top: 2%;
  }

  .sub {
    margin: 2% 5% 5% 1%;
  }
}
@media (max-width: 767px) {
  .email {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media (min-width: 865px) and (max-width: 1400px) {
  .email {
    margin-top: 7%;
  }
}
@media (min-width: 1400px) {
  .email {
    margin-top: 5%;
  }
}
</style>
