<template>
  <div
    class="modal fade"
    id="error-modal"
    tabindex="-1"
    aria-labelledby="ErrorModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div id="modal-msg" class="modal-body">
          <p class="error">Une erreur est survenue.</p>
          <p>Merci de réessayer ultérieurement.</p>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ErrorModal",
};
</script>
<style scoped>
#error-modal .modal-header {
  border-bottom: none;
}

#error-modal .modal-footer {
  border-top: none;
}

#error-modal .modal-body p,
#error-modal .modal-body .error {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  text-align: center;
  letter-spacing: 0em;
}

#error-modal .modal-body p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
#error-modal .modal-body .error {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

#error-modal .modal-dialog .modal-content {
  background-color: #f2dede;
  color: #d93f26;
}
</style>
