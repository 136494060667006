import { createRouter, createWebHistory } from "vue-router";
import DiscoverDigtop from "./components/help/page/DiscoverDigtop.vue";
import PatientAccount from "./components/help/page/PatientAccount.vue";
import ProfessionalAccount from "./components/help/page/ProfessionalAccount.vue";
import VerifyEmail from "./components/help/page/VerifyEmail.vue";
import AccessAccount from "./components/help/page/AccessAccount.vue";
import ConfigureAccount from "./components/help/page/ConfigureAccount.vue";
import MyAppointments from "./components/help/page/MyAppointments.vue";
import Teleconsultation from "./components/help/page/Teleconsultation.vue";
import ShareDocuments from "./components/help/page/ShareDocuments.vue";
import ContactUs from "./components/help/page/ContactUs.vue";

const routes = [
  {
    path: "/",
    name: "Digtop | Prenez un RDV avec un praticien en médecines douces",
    component: () => import("./components/Home.vue"),
  },
  {
    path: "/search",
    name: "Digtop | Recherchez un praticien",
    component: () => import("./components/home/SearchPage.vue"),
    meta: {
      metaTags: [
        {
          property: "og:title",
          content:
            "Recherche praticien, prendre rendez vous en ligne en toute simplicité",
        },
      ],
    },
  },
  {
    path: "/patient-registration",
    name: "S’inscrire à Digtop - Créer mon compte Digtop",
    component: () => import("./components/home/PatientRegistartion.vue"),
    meta: {
      metaTags: [
        {
          name: "description",
          content:
            "Créez votre compte Digtop, prendre rendez vous en ligne en toute simplicité",
        },
        {
          property: "og:title",
          content:
            "S’inscrire à Digtop et prendre rendez vous en ligne en toute simplicité",
        },
        {
          property: "og:description",
          content:
            "Prendre rendez vous ou effectuez une téléconsultation facilement avec votre pro près de chez vous.",
        },
      ],
    },
  },
  {
    path: "/privacy",
    name: "Digtop | Confidentialité",
    component: () => import("./components/Privacy.vue"),
    meta: {
      metaTags: [
        {
          name: "description",
          content:
            "Nous accordons une importance capitale à la protection et la sécurisation de vos données personnelles en assurant la conformité au RGPD",
        },
        {
          property: "og:title",
          content: "Conformité RGPD",
        },
        {
          property: "og:description",
          content:
            "Nous accordons une importance capitale à la protection et la sécurisation de vos données personnelles en assurant la conformité au RGPD",
        },
      ],
    },
  },
  {
    path: "/contact/:id/:fullName",
    name: "Digtop | Prenez rendez vous en ligne avec ",
    component: () => import("./components/home/ContactPage.vue"),
    meta: {
      metaTags: [
        {
          name: "description",
          content: "Prenez rendez-vous en ligne avec :fullName",
        },
        {
          property: "og:title",
          content: "Prenez rendez-vous en ligne avec :fullName",
        },
        {
          property: "og:description",
          content: "Prenez rendez-vous en ligne avec :fullName",
        },
      ],
    },
  },
  {
    path: "/appointment/:id/:fullName",
    name: "Digtop | Réservez vote rendez-vous avec ",
    component: () => import("./components/appointment/Appointment.vue"),
  },
  {
    path: "/resume/:id/:fullName",
    name: "Digtop | Prenez rendez vous avec ",
    component: () => import("./components/home/UnregisteredProPage.vue"),
    meta: {
      metaTags: [
        {
          name: "description",
          content: "Prenez rendez-vous avec :fullName",
        },
        {
          property: "og:title",
          content: "Prenez rendez-vous avec :fullName",
        },
        {
          property: "og:description",
          content: "Prenez rendez-vous avec :fullName",
        },
      ],
    },
  },
  {
    path: "/professional/delete-file",
    name: "Digtop | Confirmation du suppression du Compte Praticien",
    component: () => import("./components/home/ConfirmationDeleteUnrePro"),
  },
  {
    path: "/aide",
    name: "Digtop guide - Consultez le centre d'aide pour patients",
    component: () => import("./components/help/HelpHome.vue"),
    meta: {
      metaTags: [
        {
          name: "description",
          content:
            "Vous recherchez une information ? Les questions les plus fréquemment posées sont recensées dans notre guide.",
        },
        {
          property: "og:title",
          content:
            "Digtop guide - Consultez le centre d'aide pour patient et praticien",
        },
        {
          property: "og:description",
          content:
            "Vous recherchez une information ? Les questions les plus fréquemment posées sont recensées dans notre faq",
        },
      ],
    },
    children: [
      {
        path: "decouvir-digtop",
        name: "Centre d’aide Digtop - Vous avez besoin d'aide ? - Découvrir Digtop",
        component: DiscoverDigtop,
        meta: {
          metaTags: [
            {
              name: "description",
              content:
                "Digtop, l'application la plus complète et la plus performante pour le praticien et le professionnel",
            },
            {
              property: "og:title",
              content: "Digtop Service Client - Centre d aide",
            },
            {
              property: "og:description",
              content:
                "Digtop, l'application la plus complète et la plus performante pour le praticien et le professionnel",
            },
          ],
        },
      },
      {
        path: "compte-patient",
        name: "Digtop | Aide : compte patient",
        component: PatientAccount,
        meta: {
          metaTags: [
            {
              name: "description",
              content:
                "Pour s’inscrire sur Digtop et créer un nouveau compte Digtop patient",
            },
            {
              property: "og:title",
              content:
                "Aide : Comment créer un compte Digtop patient Digtop Service Client",
            },
            {
              property: "og:description",
              content:
                "Pour s’inscrire sur Digtop et créer un nouveau compte Digtop patient",
            },
          ],
        },
      },
      {
        path: "compte-praticien",
        name: "Digtop | Aide : compte praticien",
        component: ProfessionalAccount,
        meta: {
          metaTags: [
            {
              name: "description",
              content: "S'inscrire sur Digtop en tant que professionnel",
            },
            {
              property: "og:title",
              content: "Aide : Comment créer un compte praticien - faq",
            },
            {
              property: "og:description",
              content: "S'inscrire sur Digtop en tant que professionnel",
            },
          ],
        },
      },
      {
        path: "verifier-email",
        name: "Digtop | Aide : vérifier email",
        component: VerifyEmail,
        meta: {
          metaTags: [
            {
              name: "description",
              content: "Comment activer mon compte et vérifier mon email",
            },
            {
              property: "og:title",
              content: "Aide : Vérifier mon email et activer mon compte",
            },
            {
              property: "og:description",
              content: "Comment activer mon compte et vérifier mon email",
            },
          ],
        },
      },
      {
        path: "connecter-compte",
        name: "Digtop | Aide : Se connecter à mon compte",
        component: AccessAccount,
        meta: {
          metaTags: [
            {
              property: "og:title",
              content: "Aide : Se connecter a mon compte",
            },
          ],
        },
      },
      {
        path: "configurer-compte",
        name: "Digtop | Aide : Configurer mon compte",
        component: ConfigureAccount,
        meta: {
          metaTags: [
            {
              name: "description",
              content: "Comment configurer mon compte pro",
            },
            {
              property: "og:title",
              content: "Aide : Comment configurer mon compte",
            },
            {
              property: "og:description",
              content: "Comment configurer mon compte pro",
            },
          ],
        },
      },
      {
        path: "mes-rendez-vous",
        name: "Digtop | Aide : mes rdv",
        component: MyAppointments,
        meta: {
          metaTags: [
            {
              name: "description",
              content: "Comment prendre un rendez-vous",
            },
            {
              property: "og:title",
              content: "Aide : mes rendez-vous",
            },
            {
              property: "og:description",
              content: "Comment prendre un rendez-vous",
            },
          ],
        },
      },
      {
        path: "teleconsultation",
        name: "Digtop | Aide : téléconsultation",
        component: Teleconsultation,
        meta: {
          metaTags: [
            {
              name: "description",
              content:
                "Comment démarrer une téléconsultation en toute sécurité - RSE",
            },
            {
              property: "og:title",
              content: "Aide : Comment consulter votre professionnel en visio",
            },
            {
              property: "og:description",
              content:
                "Comment démarrer une téléconsultation  en toute sécurité - RSE",
            },
          ],
        },
      },
      {
        path: "partager-documents",
        name: "Digtop | Aide : partager documents",
        component: ShareDocuments,
        meta: {
          metaTags: [
            {
              name: "description",
              content:
                "Digtop vous facilite le partage de document pendant ou hors visio",
            },
            {
              property: "og:title",
              content: "Aide : Partage de document avec mon pro",
            },
            {
              property: "og:description",
              content:
                "Digtop vous facilite le partage de document pendant ou hors visio",
            },
          ],
        },
      },
      {
        path: "contactez-nous",
        name: "Digtop | Contactez-nous",
        component: ContactUs,
        meta: {
          metaTags: [
            {
              name: "description",
              content: "Prendre contact facilement avec la teams support",
            },
            {
              property: "og:title",
              content: "Equipe support - Contact",
            },
            {
              property: "og:description",
              content: "Prendre contact facilement avec la teams support",
            },
          ],
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
      };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

router.beforeEach((to, from, next) => {
  let fullName = "";
  if (
    to.fullPath.startsWith("/contact/") ||
    to.fullPath.startsWith("/appointment/") ||
    to.fullPath.startsWith("/resume")
  ) {
    let fullNameTab = to.params.fullName.split("-");
    for (let i in fullNameTab) {
      fullName +=
        fullNameTab[i][0].toUpperCase() +
        fullNameTab[i].substring(1, fullNameTab[i].length) +
        " ";
    }
  }
  document.title = to.name + fullName;
  let ogUrl = `${window.location.origin}`;
  if (to.fullPath.startsWith("/search")) {
    ogUrl += "/search";
  } else {
    ogUrl += `${to.fullPath}`;
  }
  document.head
    .querySelector('link[rel="canonical"]')
    .setAttribute("href", ogUrl);
  document.head.querySelector('meta[property="og:url"]').content = ogUrl;
  if (to.meta?.metaTags) {
    const metaTags = to.meta.metaTags;
    metaTags.forEach((tag) => {
      const { name, property, content } = tag;
      if (name) {
        document.head.querySelector(`meta[name="${name}"]`).content =
          content.replaceAll(":fullName", fullName);
      }
      if (property) {
        document.head.querySelector(`meta[property="${property}"]`).content =
          content.replaceAll(":fullName", fullName);
      }
    });
  }
  next();
});

export default router;
