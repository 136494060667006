<template>
  <div
    class="modal fade cookies"
    tabindex="-1"
    id="modal"
    role="dialog"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div>
            <p class="title">Politique relative aux cookies</p>
            <p>
              Les cookies nous permettent de vous offrir des fonctionnalités
              utiles et de mesurer les performances afin d’améliorer votre
              expérience.
              <br />Veuillez consulter
              <a
                target="_blank"
                :href="
                  app_host + '/api/v1.0/docs/general_conditions_of_use.pdf'
                "
                ><u>notre politique de confidentialité</u></a
              >
              pour en savoir plus.
            </p>

            <ul class="row according justify-content-evenly">
              <li class="row">
                <div class="col">
                  <p class="accordion-header" id="nestedHeadingOne">
                    <button
                      class="accordion-button no-arrow"
                      type="button"
                      id="collapseButton"
                      @click="changeText"
                      data-bs-toggle="collapse"
                      data-bs-target="#nestedCollapseOne"
                      aria-expanded="false"
                      aria-controls="#nestedCollapseOne"
                    >
                      {{ collapseButton }}
                    </button>
                  </p>
                </div>
                <div
                  id="nestedCollapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="nestedHeadingOne"
                  data-bs-parent="#myNestedAccordion"
                >
                  <div class="accordion-body">
                    <ul class="according">
                      <li class="row">
                        <div class="col">
                          <input
                            class="col form-check-input header"
                            type="checkbox"
                            id="Fonctionnement"
                          />
                          <label class="form-check-label" for="Fonctionnement"
                            >Fonctionnement</label
                          >
                          <div class="content content-acc">
                            <p class="p-params">
                              Ces cookies sont nécessaires pour le bon
                              fonctionnement de notre site internet, ils vous
                              permettent d’en utiliser les fonctionnalités les
                              plus importantes et de naviguer en toute
                              tranquillité.
                            </p>
                          </div>
                        </div>
                        <span class="col-2 form-switch">
                          <input
                            class="form-check-input c-not-allowed"
                            type="checkbox"
                            id="fonctionnementSwitch"
                            checked
                            disabled
                          />
                        </span>
                      </li>
                      <li class="row">
                        <div class="col">
                          <input
                            class="form-check-input header"
                            type="checkbox"
                            id="Performances"
                          />
                          <label class="form-check-label" for="Performances"
                            >Statistiques</label
                          >
                          <div class="content content-acc">
                            <p class="p-params">
                              Ces cookies permettent d'analyser l'audience et le
                              trafic sur notre site ainsi, nous pouvons
                              personnaliser le contenu et les annonces.
                            </p>
                          </div>
                        </div>
                        <span class="col-2 form-switch">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="ga-checkbox"
                            checked
                          />
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            @click="acceptCookies('required')"
            id="digtop"
            class="DigTop me-3 btn btn-primary"
          >
            Cookies nécessaires uniquement
          </button>
          <button
            type="button"
            @click="acceptCookies(acceptCookiesButton)"
            id="all"
            class="all me-3 btn btn-primary"
          >
            {{ acceptCookiesButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as bootstrap from "bootstrap";

const app_host = process.env.VUE_APP_DIGTOP_APP_HOST;
const gtag_id = process.env.VUE_APP_GTAG_ID;
const otherOptions = "Autres options";
const hide = "Masquer";
const acceptAllCookies = "Accepter tous les cookies";
const confirmMyChoice = "Confirmer mon choix";
const digtopCookieName = "digtop_cookie";
const digtopCookieMaxAge = 60 * 60 * 24 * 30 * 12;

export default {
  data() {
    return {
      showCookiesBanner: true,
      app_host: app_host,
      collapseButton: otherOptions,
      acceptCookiesButton: acceptAllCookies,
    };
  },
  mounted() {
    var digtopCookie = this.$cookies.get(digtopCookieName);
    if (!digtopCookie) {
      this.deleteAllCookies();
      new bootstrap.Modal("#modal").show();
    } else if (digtopCookie === "all" || digtopCookie.includes("_ga")) {
      this.googleTag();
    }
  },
  methods: {
    acceptCookies(acceptedCookies) {
      this.deleteAllCookies();
      switch (acceptedCookies) {
        case acceptAllCookies:
          this.$cookies.set(digtopCookieName, "all", digtopCookieMaxAge);
          this.googleTag();
          break;
        case "required":
          this.$cookies.set(digtopCookieName, "required", digtopCookieMaxAge);
          break;
        default:
          var digtopCookiesValue = "required";
          if (document.getElementById("ga-checkbox").checked) {
            digtopCookiesValue += "__ga";
            this.googleTag();
          }
          this.$cookies.set(
            digtopCookieName,
            digtopCookiesValue,
            digtopCookieMaxAge
          );
      }
      if (!this.$cookies.keys().length || this.$cookies.keys().length === 0) {
        alert(
          "Création des cookies impossible ! Veuillez débloquer la création des cookies dans votre navigateur."
        );
      }
      bootstrap.Modal.getInstance("#modal").hide();
    },
    googleTag() {
      var scriptTag = document.createElement("script");
      scriptTag.async = true;
      scriptTag.src = "https://www.googletagmanager.com/gtag/js?id=" + gtag_id;
      document.head.appendChild(scriptTag);
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", gtag_id);
    },
    deleteAllCookies() {
      var cookies = document.cookie.split(";");
      for (const cookie of cookies) {
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
      }
    },
    changeText() {
      this.collapseButton =
        this.collapseButton === otherOptions ? hide : otherOptions;
      this.acceptCookiesButton =
        this.acceptCookiesButton === confirmMyChoice
          ? acceptAllCookies
          : confirmMyChoice;
    },
  },
};
</script>
<style scoped>
#modal .form-check-input:disabled {
  background-color: #34c759;
  border-color: #34c759;
  margin-left: auto;
}

#modal .c-not-allowed {
  cursor: not-allowed !important;
}

#modal .all:hover {
  background-color: #34c759;
  border-color: #34c759;
}

#modal .accordion-body {
  padding: 1% 0% 0% 0%;
}

#modal .modal .accordion-button:not(.collapsed) {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #1f294e;
}

.modal .accordion-button.collapsed {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #1f294e;
}

.modal ul {
  padding-left: 0rem;
}

.modal .form-switch .form-check-input:checked {
  background-color: #34c759;
  border-color: #34c759;
  margin-left: auto;
}

.modal .form-switch .form-check-input {
  width: 3em;
  height: 1.5rem;
  margin-left: auto;
}

.modal .according li {
  list-style: none;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #1f294e;
}

.modal .p-params {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #7a7a7a;
}

.modal .according li label {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 2% 0%;
}

.modal label::before {
  content: "+";
  padding-right: 1%;
}

.modal input[type="checkbox"] {
  display: none;
}

.modal .col-2 input[type="checkbox"] {
  display: block;
}

.modal .according .content {
  max-height: 0;
  max-width: 90%;
  overflow: hidden;
  transition: 0.3s;
}

.modal .according input[type="checkbox"]:checked + label + .content {
  padding: 1%;
  max-height: fit-content;
}

.modal .according input[type="checkbox"]:checked + label::before {
  content: "-";
}

.modal .DigTop {
  background: #0993e8;
  box-shadow: 0 0 0 7px rgba(9, 147, 232, 0.3);
  border: none;
  width: auto;
}

.modal .all {
  background: #3aaa35;
  box-shadow: 0 0 0 7px rgba(58, 170, 53, 0.3);
  border: none;
  width: fit-content;
}

.modal .all:disabled {
  background: #3aaa35;
  box-shadow: 0 0 0 7px rgba(58, 170, 53, 0.3);
  border: none;
  opacity: 0.65;
  width: fit-content;
}

.modal .no {
  background: #1f294e;
  box-shadow: 0 0 0 7px rgba(31, 41, 78, 0.3);
  border: none;
}

.modal .title {
  padding-top: 2rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 171%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #006dae;
}

.modal p {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #1f294e;
}

.modal a {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;

  color: #0993e8;
}

.modal .modal-footer {
  justify-content: flex-end;
  border-top: transparent;
  padding: 0.25rem 2rem 2rem 2rem;
}

#modal.modal-header {
  padding: 1.5rem 2rem 0rem 2rem;
  border: none;
}

#modal .modal-body {
  padding: 0rem 2rem;
}

@media (max-width: 992px) {
  .modal .modal-footer {
    justify-content: flex-start;
    margin-inline-start: 0%;
    gap: 1rem;
  }
  .form-switch {
    padding-left: 1em;
  }
}
</style>
