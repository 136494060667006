<template>
  <div>
    <div class="patient">
      <div class="titre-pre">
        <h1 class="title">Créer votre compte Digtop</h1>
      </div>
      <h1 class="title sub">Créer un compte Digtop patient</h1>
      <div class="paragraph">
        <p>
          Pour s’inscrire sur Digtop et créer un nouveau compte Digtop patient :
        </p>
        <div>
          <p>
            1.
            <span>
              Cliquez sur <span class="subtitle">Se connecter</span> (situé en
              haut à droite de la page d'accueil)</span
            >
          </p>
          <div>
            <img src="@/assets/help/patientAccount/1.jpg" alt="" />
            <p>
              2.
              <span>
                Cliquez sur le bouton
                <span class="subtitle"
                  >Je m'inscris en tant que patient</span
                ></span
              >
            </p>
            <div>
              <img src="@/assets/help/patientAccount/2.jpg" alt="" />
              <p>3.<span> Renseignez vos informations : </span></p>
              <div class="order ml-1">
                <p>&#8226; <u>Prénom et Nom</u></p>
                <p>&#8226; <u>Téléphone portable</u></p>
                <p>&#8226; <u>Adresse mail</u></p>
                <p>&#8226; <u>Mot de passe et confirmer mot de passe</u></p>
              </div>
              <div>
                <img src="@/assets/help/patientAccount/3.jpg" alt="" />
              </div>
              <p>
                Cocher la case J'accepte les Conditions générales d’utilisation
                de Digtop, puis cliquer sur le bouton "
                <span class="subtitle">S'inscrire</span> " .
              </p>
              <div>
                <img src="@/assets/help/patientAccount/4.jpg" alt="" />
              </div>
              <p>
                Une fois votre compte est créé, allez dans votre boite mail et
                suivrez les indications envoyées par email, en cliquant sur le
                bouton “<span class="subtitle">Activer mon compte</span>”
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientAccount",
};
</script>
<style scoped>
.titre-pre {
  margin-left: 2%;
  display: flex;
}

.title {
  color: #1f294e;
  font-weight: 600 !important;
  font-size: 20px !important;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  margin-left: 2%;
}
.sub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;

  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  margin: 2% 5% 5% 4%;
  color: #f39200;
}
.paragraph {
  margin-top: 1.8%;
  margin-left: 4%;
  color: #1f294e;
  font-family: "Poppins", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.paragraph img {
  width: 85%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.subtitle {
  font-weight: 700;
  font-size: 16px;
}

.order {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #1f294e;
}

.ml-1 {
  margin-left: 1% !important;
  margin-top: 4% !important;
}
@media (max-width: 864px) {
  .paragraph {
    margin-left: 1.5%;
  }
  .titre-pre {
    margin-left: -1%;
    margin-top: 2%;
  }

  .sub {
    margin: 2% 5% 5% 1%;
  }
}
@media (max-width: 767px) {
  .patient {
    margin-left: 8%;
    margin-right: 2%;
    margin-top: 6%;
  }

  .paragraph img {
    width: 95%;
    margin-top: 5%;
    margin-bottom: 8%;
  }
}
@media (min-width: 865px) and (max-width: 1400px) {
  .patient {
    margin-top: 7%;
  }
}
@media (min-width: 1400px) {
  .patient {
    margin-top: 5%;
  }
}
</style>
